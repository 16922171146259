/*
  HOW TO USE
  ==========

  * import the component

    import Popup from "../components/popup"

  * use the component
 
    <Popup show={showPopup} toggleHandler={togglePopup} padding={false}>
      CONTENT GOES HERE
    </Popup>

  * showPopup is presumably handled through useState, e.g. like so:
 
    const [showPopup, setShowPopup] = useState(true)

  * togglePopup just reverses showPopup, e.g. like so:

    const togglePopup = () => {
      setShowPopup(!showPopup)
    }

  * padding says whether or not to include padding in the popup

  FOR A TIMED POPUP
  =================

  Define showPopup like so:

  import * as moment from 'moment'

  const [showPopup, setShowPopup] = useState(
    moment().isAfter("2020-01-01T00:00:01")
  )
*/

import React from 'react'

import styled, { createGlobalStyle, css } from 'styled-components'
import { Transition } from 'react-transition-group'
import { hideVisually } from 'polished'

import Portal from './portal'
import Overlay from './overlay'

export default ({ children, toggleHandler, show, padding }) => {
  return (
    <Transition
      timeout={{ enter: 0, exit: 500 }}
      mountOnEnter
      unmountOnExit
      in={show}
    >
      {state => (
        <>
          <FreezeBody />
          <Portal>
            <Overlay clickHandler={toggleHandler} state={state} />
            <Popup className={`state-${state}`} padding={padding}>
              <CloseButton onClick={toggleHandler}>
                <span>閉じる</span>
              </CloseButton>
              {children}
            </Popup>
          </Portal>
        </>
      )}
    </Transition>
  )
}

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10000;
  min-width: 250px;
  min-height: 250px;
  /* width: calc(100vw - 5rem);
  height: calc(100vh - 5rem);
  max-width: 600px;
  max-height: 600px; */

  background: #fff;
  transform: translate(-50%, -50%);

  transition: opacity 0.5s;

  border: 0.5rem solid red;

  @media (min-width: 26.5625em) {
    border-width: 1rem;
  }

  ${({ padding }) =>
    padding !== false &&
    css`
      padding: 2rem 0.5rem;
      @media (min-width: 23.4375em) {
        padding: 2rem 1rem;
      }

      @media (min-width: 26.5625em) {
        padding: 2rem;
      }
    `}

  &.state-entering,
  &.state-exiting,
  &.state-exited {
    opacity: 0;
  }
  &.state-entered {
    opacity: 1;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  width: 2rem;
  height: 2rem;
  border: 0;
  background: red;
  cursor: pointer;
  transition: background 0.25s;

  &:hover {
    background: orange;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 1.5rem;
    height: 0.25rem;
    background: #fff;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  span {
    ${hideVisually()}
  }
`

const FreezeBody = createGlobalStyle`
  body {
    overflow: hidden;
  }
`
