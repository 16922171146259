import React, { useState, useEffect } from 'react'
import { graphql, withPrefix, Link } from 'gatsby'

import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import InViewMonitor from 'react-inview-monitor'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SEO from '../components/seo'

import * as Layout from '../layouts/layout'
import * as FadeIn from '../components/fadeIn'

import Popup from '../components/popup'
import imgCoronaVirus from '../../static/img/coronavirus.jpg'
import imgCoronaVirusAbout from '../../static/img/coronavirus-about.jpg'

const Index = ({ lang, data }) => {
  const [showPopup, setShowPopup] = useState(false)
  const [showPopupAbout, setShowPopupAbout] = useState(false)

  const togglePopup = () => {
    setShowPopup(!showPopup)
  }

  const togglePopupAbout = () => {
    setShowPopupAbout(!showPopupAbout)
  }

  return (
    <>
      <SEO title="" />
      <Hero className="hero">
        <img src={withPrefix('/img/hero.jpg')} alt="hero" />
        <HeroHeader>
          <FadeIn.Left margin="0%">
            <h1>FUYO EN</h1>
          </FadeIn.Left>
          <FadeIn.Left margin="0%">
            <p>
              {lang === 'ja' &&
                '笑顔を添えて、本物の四川料理の美味しさを提供します'}
              {lang === 'en' &&
                'Serve with a smile and offer the real taste of Sichuan cuisine'}
              {lang === 'zh' && '带着真诚的笑容为您提供最正宗美味的四川料理'}
            </p>
          </FadeIn.Left>
        </HeroHeader>
        <HeroSNS>
          <FadeIn.Left margin="0%">
            <p>Follow Me</p>
          </FadeIn.Left>
          <Icons>
            <FadeIn.Up margin="0%">
              <a
                href="https://www.facebook.com/FUYOEN/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </a>
              <a
                href="https://www.instagram.com/fuyoen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </a>
              <a
                href="https://twitter.com/fuyoenosaka"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </a>
              <a
                href="https://page.line.me/aiy6070n"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'line']} />
              </a>
            </FadeIn.Up>
          </Icons>
        </HeroSNS>
        {/* <CoronaVirusAbout onClick={togglePopupAbout}>
          新型コロナウイルス感染症に対する当社の取り組みに関しまして
        </CoronaVirusAbout> */}
      </Hero>
      {/* <News>
        <NewsTitle>
          <h2>News</h2>
          <p>
            {lang === 'ja' && 'ニュース'}
            {lang === 'en' && ''}
            {lang === 'zh' && '新闻'}
          </p>
        </NewsTitle>
        <NewsBody>
          <NewsDate>{data.allWordpressPost.edges[0].node.date}</NewsDate>
          <NewsContent>{data.allWordpressPost.edges[0].node.title}</NewsContent>
        </NewsBody>
      </News> */}
      <Grid>
        <GridBodyAbove>
          <GridBodyAboveTxt>
            <GridBodyAboveTxtBox>
              <FadeIn.Left>
                <h3>
                  {lang === 'ja' &&
                    '正真正銘、本物の四川料理の美味しさを伝えたい'}
                  {lang === 'en' &&
                    'I want to convey the true taste of authentic Sichuan cuisine'}
                  {lang === 'zh' && '想传递地地道道，正宗的，美味的四川料理。'}
                </h3>
              </FadeIn.Left>
              <FadeIn.Left>
                <p>
                  {lang === 'ja' &&
                    '2300年以上の歴史を持つ食文化である「四川料理」は四大中華料理の一つ。紀元前316年著作の「華陽国志」に登場する古蜀の食文化で「名は巴蜀三千里を馳せ、味は江南十二州を圧倒する」と称される。'}
                  {lang === 'en' &&
                    'Sichuan cuisine, a food culture with a history of over 2300 years, is one of the four major Chinese dishes. The food culture of the ancient tombs that appear in "Haoyang Kunishi" written in 316 BC, is said to be "The name surpasses Sanuki Sanri and the taste overwhelms the province of Gangnam".'}
                  {lang === 'zh' &&
                    '“四川菜”是四大中餐之一，具有2300多年历史的饮食文化。公元前316年著作的“华阳国志”中登场的古蜀饮食文化有“名驰巴蜀三千里,味压江南十二州”之称。'}
                </p>
              </FadeIn.Left>
            </GridBodyAboveTxtBox>
          </GridBodyAboveTxt>
          <GridBodyAboveImg>
            <img src={withPrefix('/img/concept.jpg')} alt="hero" />
            <InViewMonitor
              childPropsInView={{ isActive: true }}
              intoViewMargin="50%"
            >
              <GridBodyAboveImgWrapper />
            </InViewMonitor>
          </GridBodyAboveImg>
        </GridBodyAbove>
        <GridBodyBelow>
          <GridBodyBelowBox>
            <GridBodyBelowTxt>
              <FadeIn.Right>
                <InViewMonitor childPropsInView={{ isActive: true }}>
                  <GridBodyBelowH2>Concept</GridBodyBelowH2>
                </InViewMonitor>
              </FadeIn.Right>
              <FadeIn.Right>
                <h3>
                  {lang === 'ja' && '芙蓉苑のこだわり'}
                  {lang === 'en' && 'Perseverance'}
                  {lang === 'zh' && '芙蓉苑的观念'}
                </h3>
              </FadeIn.Right>

              <FadeIn.Right>
                <p>
                  {lang === 'ja' &&
                    '芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。'}
                  {lang === 'en' &&
                    'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                  {lang === 'zh' &&
                    '芙蓉苑重视地道的四川饮食文化，我们把真正的四川菜味道传递给日本作为我们的使命。我们向您保证，会把地地道道的，美味的“正宗的四川料理”和亲切的“笑容”送给客人。'}
                </p>
              </FadeIn.Right>

              <FadeIn.Up>
                <Buttons>
                  <Button to="/concept">
                    <p>
                      {lang === 'ja' && 'こだわりをみる'}
                      {lang === 'en' && 'Look at sticking'}
                      {lang === 'zh' && '观察观念'}
                    </p>
                  </Button>
                </Buttons>
              </FadeIn.Up>
            </GridBodyBelowTxt>
          </GridBodyBelowBox>
        </GridBodyBelow>
      </Grid>
      <Layout.Section>
        <Layout.Container>
          <Header>
            <FadeIn.Left>
              <h2>
                {lang === 'ja' && '芙蓉苑グループの店舗情報'}
                {lang === 'en' && 'Look at sticking'}
                {lang === 'zh' && '芙蓉苑集团的店铺信息'}
              </h2>
            </FadeIn.Left>
            {/* <FadeIn.Left>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
                quaerat expedita totam sit? Maxime ex placeat sunt error deleniti
                sequi reiciendis asperiores, dolor vero, suscipit numquam officiis
                deserunt iusto dicta!
              </p>
            </FadeIn.Left> */}
          </Header>
          <Layout.Row>
            <CardCol6>
              <CardRow>
                <CardCol>
                  <img src={withPrefix('/img/store-1.jpg')} alt="芙蓉苑 本店" />
                </CardCol>
                <CardCol>
                  <FadeIn.Left>
                    <CardHeader>
                      {lang === 'ja' && '芙蓉苑 本店'}
                      {lang === 'en' && 'Look at sticking'}
                      {lang === 'zh' && '芙蓉苑 本店'}
                    </CardHeader>
                    <CardBody>
                      <a
                        href="https://tabelog.com/osaka/A2701/A270201/27089496/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && '食べログはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && '食べログ点击这里'}
                      </a>
                      <a
                        href="https://www.hotpepper.jp/strJ001137049/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'ホットペッパーはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'ホットペッパー点击这里'}
                      </a>
                      <a
                        href="https://retty.me/area/PRE27/ARE94/SUB62304/100001260307/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'Rettyはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'Retty点击这里'}
                      </a>
                    </CardBody>
                  </FadeIn.Left>
                </CardCol>
              </CardRow>
            </CardCol6>
            <CardCol6>
              <CardRow>
                <CardCol>
                  <img
                    src={withPrefix('/img/store-2.jpg')}
                    alt="芙蓉火鍋城 長堀橋店"
                  />
                </CardCol>
                <CardCol>
                  <FadeIn.Left>
                    <CardHeader>
                      {lang === 'ja' && '芙蓉火鍋城 長堀橋店'}
                      {lang === 'en' && 'Look at sticking'}
                      {lang === 'zh' && '芙蓉火鍋城 長堀橋店'}
                    </CardHeader>
                    <CardBody>
                      <a
                        href="https://tabelog.com/osaka/A2701/A270201/27102144/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && '食べログはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && '食べログ点击这里'}
                      </a>
                      <a
                        href="https://www.hotpepper.jp/strJ001169246/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'ホットペッパーはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'ホットペッパー点击这里'}
                      </a>
                    </CardBody>
                  </FadeIn.Left>
                </CardCol>
              </CardRow>
            </CardCol6>
            <CardCol6>
              <CardRow>
                <CardCol>
                  <img
                    src={withPrefix('/img/store-3.jpg')}
                    alt="芙蓉麻婆麺 十三店"
                  />
                </CardCol>
                <CardCol>
                  <FadeIn.Left>
                    <CardHeader>
                      {lang === 'ja' && '芙蓉麻婆面 十三店'}
                      {lang === 'en' && 'Look at sticking'}
                      {lang === 'zh' && '芙蓉麻婆面 十三店'}
                    </CardHeader>
                    <CardBody>
                      <a
                        href="https://tabelog.com/osaka/A2701/A270302/27097472/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && '食べログはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && '食べログ点击这里'}
                      </a>
                      <a
                        href="https://www.hotpepper.jp/strJ001205836/party/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'ホットペッパーはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'ホットペッパー点击这里'}
                      </a>
                    </CardBody>
                  </FadeIn.Left>
                </CardCol>
              </CardRow>
            </CardCol6>
            {/* <CardCol6>
              <CardRow>
                <CardCol>
                  <img
                    src={withPrefix('/img/store-4.jpg')}
                    alt="芙蓉麻婆麺 三ノ宮店"
                  />
                </CardCol>
                <CardCol>
                  <FadeIn.Left>
                    <CardHeader>
                      {lang === 'ja' && '芙蓉麻婆面 三宮店'}
                      {lang === 'en' && 'Look at sticking'}
                      {lang === 'zh' && '芙蓉麻婆面 三宮店'}
                    </CardHeader>
                    <CardBody>
                      <a
                        href="https://tabelog.com/hyogo/A2801/A280101/28053761/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && '食べログはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && '食べログ点击这里'}
                      </a>
                      <a
                        href="https://www.hotpepper.jp/strJ001210239/party/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'ホットペッパーはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'ホットペッパー点击这里'}
                      </a>
                    </CardBody>
                  </FadeIn.Left>
                </CardCol>
              </CardRow>
            </CardCol6> */}
            <CardCol6>
              <CardRow>
                <CardCol>
                  <img
                    src={withPrefix('/img/store-5.jpg')}
                    alt="芙蓉火鍋城 京都店"
                  />
                </CardCol>
                <CardCol>
                  <FadeIn.Left>
                    <CardHeader>
                      {lang === 'ja' && '芙蓉火鍋城 京都店'}
                      {lang === 'en' && 'Look at sticking'}
                      {lang === 'zh' && '芙蓉火鍋城 京都店'}
                    </CardHeader>
                    <CardBody>
                      <a
                        href="https://tabelog.com/kyoto/A2601/A260301/26032604/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && '食べログはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && '食べログ点击这里'}
                      </a>
                      <a
                        href="https://www.hotpepper.jp/strJ001217665/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'ホットペッパーはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'ホットペッパー点击这里'}
                      </a>
                    </CardBody>
                  </FadeIn.Left>
                </CardCol>
              </CardRow>
            </CardCol6>
            {/* <CardCol6>
              <CardRow>
                <CardCol>
                  <img
                    src={withPrefix('/img/store-6.jpg')}
                    alt="FUYOENりんくう公園店"
                  />
                </CardCol>
                <CardCol>
                  <FadeIn.Left>
                    <CardHeader>
                      {lang === 'ja' && 'FUYOENりんくう公園店'}
                      {lang === 'en' && 'Look at sticking'}
                      {lang === 'zh' && 'FUYOENりんくう公園店'}
                    </CardHeader>
                    <CardBody>
                      <a
                        href="https://tabelog.com/kyoto/A2601/A260301/26032604/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && '食べログはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && '食べログ点击这里'}
                      </a>
                      <a
                        href="https://www.hotpepper.jp/strJ001245032/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'ホットペッパーはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'ホットペッパー点击这里'}
                      </a>
                    </CardBody>
                  </FadeIn.Left>
                </CardCol>
              </CardRow>
            </CardCol6> */}
            {/* <CardCol6>
              <CardRow>
                <CardCol>
                  <img
                    src={withPrefix('/img/store-7.jpg')}
                    alt="FUYOENりんくう公園店FUYOEN心斎橋PARCO店"
                  />
                </CardCol>
                <CardCol>
                  <FadeIn.Left>
                    <CardHeader>
                      {lang === 'ja' && 'FUYOEN心斎橋PARCO店'}
                      {lang === 'en' && 'FUYOEN心斎橋PARCO店'}
                      {lang === 'zh' && 'FUYOEN心斎橋PARCO店'}
                    </CardHeader>
                    <CardBody>
                      <a
                        href="https://tabelog.com/kyoto/A2601/A260301/26032604/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && '食べログはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && '食べログ点击这里'}
                      </a>
                      <a
                        href="https://www.hotpepper.jp/strJ001245032/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'ホットペッパーはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'ホットペッパー点击这里'}
                      </a>
                    </CardBody>
                  </FadeIn.Left>
                </CardCol>
              </CardRow>
            </CardCol6> */}
            {/*<CardCol6>
              <CardRow>
                <CardCol>
                  <img
                    src={withPrefix('/img/store-9.jpg')}
                    alt="狐食堂 韓国料理Someck＆FUYOENエンタメ酒場 京都店"
                  />
                </CardCol>
                <CardCol>
                  <FadeIn.Left>
                    <CardHeader>
                      {lang === 'ja' && '狐食堂 韓国料理Someck＆FUYOENエンタメ酒場 京都店'}
                      {lang === 'en' && '狐食堂 韓国料理Someck＆FUYOENエンタメ酒場 京都店'}
                      {lang === 'zh' && '狐食堂 韓国料理Someck＆FUYOENエンタメ酒場 京都店'}
                    </CardHeader>
                    <CardBody>
                      {/* <a
                        href="https://tabelog.com/kyoto/A2601/A260301/26032604/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && '食べログはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && '食べログ点击这里'}
                      </a> *
                      <a
                        href="https://www.hotpepper.jp/strJ001274954/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'ホットペッパーはこちら (FUYOEN)'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'ホットペッパー点击这里'}
                      </a>
                      <a
                        href="https://www.hotpepper.jp/strJ001274929/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'ホットペッパーはこちら (Someck)'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'ホットペッパー点击这里'}
                      </a>
                    </CardBody>
                  </FadeIn.Left>
                </CardCol>
              </CardRow>
            </CardCol6>*/}
            <CardCol6>
              <CardRow>
                <CardCol>
                  <img
                    src={withPrefix('/img/store-11.jpg')}
                    alt="芙蓉苑 渋谷PARCO店"
                  />
                </CardCol>
                <CardCol>
                  <FadeIn.Left>
                    <CardHeader>
                      {lang === 'ja' && '芙蓉苑 渋谷PARCO店'}
                      {lang === 'en' && '芙蓉苑 渋谷PARCO店'}
                      {lang === 'zh' && '芙蓉苑 渋谷PARCO店'}
                    </CardHeader>
                    <CardBody>
                      {/* <a
                        href="https://tabelog.com/kyoto/A2601/A260301/26032604/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && '食べログはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && '食べログ点击这里'}
                      </a> */}
                      <a
                        href="https://www.hotpepper.jp/strJ001282744/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'ホットペッパーはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'ホットペッパー点击这里'}
                      </a>
                    </CardBody>
                  </FadeIn.Left>
                </CardCol>
              </CardRow>
            </CardCol6>
            <CardCol6>
              <CardRow>
                <CardCol>
                  <img
                    src={withPrefix('/img/store-temp.jpg')}
                    alt="FUYOEN & SOMECK CHINESE × KOREAN"
                  />
                </CardCol>
                <CardCol>
                  <FadeIn.Left>
                    <CardHeader>
                      {lang === 'ja' && 'FUYOEN & SOMECK CHINESE × KOREAN'}
                      {lang === 'en' && 'FUYOEN & SOMECK CHINESE × KOREAN'}
                      {lang === 'zh' && 'FUYOEN & SOMECK CHINESE × KOREAN'}
                    </CardHeader>
                    <CardBody>
                      <a
                        href="https://tabelog.com/wakayama/A3001/A300101/30010790/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && '食べログはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && '食べログ点击这里'}
                      </a>
                      <a
                        href="https://www.hotpepper.jp/strJ003297042/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {lang === 'ja' && 'ホットペッパーはこちら'}
                        {lang === 'en' && 'Look at sticking'}
                        {lang === 'zh' && 'ホットペッパー点击这里'}
                      </a>
                      {/* <span>秋オープン</span> */}
                    </CardBody>
                  </FadeIn.Left>
                </CardCol>
              </CardRow>
            </CardCol6>
            <CardCol6 />
            <CardCol6>
              <CardInner>
                <FadeIn.Up>
                  <Button to="/stores">
                    <p>
                      {lang === 'ja' && '店舗一覧へ'}
                      {lang === 'en' && 'Look at sticking'}
                      {lang === 'zh' && '店铺内容'}
                    </p>
                  </Button>
                </FadeIn.Up>
                <Buttons />
              </CardInner>
            </CardCol6>
          </Layout.Row>
        </Layout.Container>
      </Layout.Section>
      <SnsSection>
        <Sns>
          <Layout.Container>
            <SnsRow>
              <SnsHeader>
                <FadeIn.Left>
                  <h2>
                    {lang === 'ja' && '芙蓉苑グループSNS'}
                    {lang === 'en' && 'Look at sticking'}
                    {lang === 'zh' && '芙蓉苑集团SNS'}
                  </h2>
                </FadeIn.Left>
                <FadeIn.Left>
                  <p>
                    {lang === 'ja' &&
                      '新作料理やお得な情報、キャンペーンを配信しております。'}
                    {lang === 'en' && 'Look at sticking'}
                    {lang === 'zh' &&
                      '正在发送新品菜肴和优惠信息，以及宣传活动。'}
                  </p>
                </FadeIn.Left>
              </SnsHeader>
            </SnsRow>
          </Layout.Container>
          <SnsIcons>
            <SnsIcon>
              <SnsFacebook
                href="https://www.facebook.com/FUYOEN/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </SnsFacebook>
            </SnsIcon>
            <SnsIcon>
              <SnsInstagram
                href="https://www.instagram.com/fuyoen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </SnsInstagram>
            </SnsIcon>
            <SnsIcon>
              <SnsLine
                href="https://page.line.me/aiy6070n"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'line']} />
              </SnsLine>
            </SnsIcon>
          </SnsIcons>
        </Sns>
      </SnsSection>
      <Grid>
        <GridBodyAbove>
          <GridBodyAboveTxt>
            <GridBodyAboveTxtBox>
              <FadeIn.Left>
                <h3>
                  {lang === 'ja' && '本場四川から特級料理人を招く'}
                  {lang === 'en' && 'Look at sticking'}
                  {lang === 'zh' && '招募四川本地的特级大厨'}
                </h3>
              </FadeIn.Left>
              <FadeIn.Left>
                <p>
                  {lang === 'ja' &&
                    '2300年以上の歴史を持つ食文化である「四川料理」は四大中華料理の一つ。紀元前316年著作の「華陽国志」に登場する古蜀の食文化で「名は巴蜀三千里を馳せ、味は江南十二州を圧倒する」と称される。'}
                  {lang === 'en' &&
                    'Sichuan cuisine, a food culture with a history of over 2300 years, is one of the four major Chinese dishes. The food culture of the ancient tombs that appear in "Haoyang Kunishi" written in 316 BC, is said to be "The name surpasses Sanuki Sanri and the taste overwhelms the province of Gangnam".'}
                  {lang === 'zh' &&
                    '“四川菜”是四大中餐之一，具有2300多年历史的饮食文化。公元前316年著作的“华阳国志”中登场的古蜀饮食文化有“名驰巴蜀三千里,味压江南十二州”之称。'}
                </p>
              </FadeIn.Left>
            </GridBodyAboveTxtBox>
          </GridBodyAboveTxt>
          <GridBodyAboveImg>
            <img src={withPrefix('/img/chefs.jpg')} alt="hero" />
            <InViewMonitor
              childPropsInView={{ isActive: true }}
              intoViewMargin="50%"
            >
              <GridBodyAboveImgWrapper />
            </InViewMonitor>
          </GridBodyAboveImg>
        </GridBodyAbove>
        <GridBodyBelow>
          <GridBodyBelowBox>
            <GridBodyBelowTxt>
              <FadeIn.Right>
                <InViewMonitor childPropsInView={{ isActive: true }}>
                  <GridBodyBelowH2>Chefs</GridBodyBelowH2>
                </InViewMonitor>
              </FadeIn.Right>
              <FadeIn.Right>
                <h3>
                  {lang === 'ja' && '芙蓉苑の料理人'}
                  {lang === 'en' && '芙蓉苑の料理人'}
                  {lang === 'zh' && '芙蓉苑的厨师'}
                </h3>
              </FadeIn.Right>
              <FadeIn.Right>
                <p>
                  {lang === 'ja' &&
                    '芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。'}
                  {lang === 'en' &&
                    'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                  {lang === 'zh' &&
                    '芙蓉苑重视地道的四川饮食文化，我们把真正的四川菜味道传递给日本作为我们的使命。我们向您保证，会把地地道道的，美味的“正宗的四川料理”和亲切的“笑容”送给客人。'}
                </p>
              </FadeIn.Right>
              <FadeIn.Up>
                <Buttons>
                  <Button to="/concept">
                    <p>
                      {lang === 'ja' && 'こだわりをみる'}
                      {lang === 'en' && '芙蓉苑の料理人'}
                      {lang === 'zh' && '观察观念'}
                    </p>
                  </Button>
                </Buttons>
              </FadeIn.Up>
            </GridBodyBelowTxt>
          </GridBodyBelowBox>
        </GridBodyBelow>
      </Grid>
      <Layout.Section>
        <Layout.Container>
          <RecruitHeader>
            <FadeIn.Left>
              <h2>
                {lang === 'ja' && '芙蓉苑グループSNS'}
                {lang === 'en' && '芙蓉苑の料理人'}
                {lang === 'zh' && '芙蓉苑集团SNS'}
              </h2>
            </FadeIn.Left>

            <FadeIn.Left>
              <Videos>
                <Video>
                  <iframe
                    title="大阪四川料理店　芙蓉苑　火鍋"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/GFAG3RFMOr4"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Video>
                <Video>
                  <iframe
                    title="大阪四川料理店　芙蓉苑　火鍋"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/AOrGsD0H6o8"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Video>
                <Video>
                  <iframe
                    title="激うま（芙蓉苑四川料理）水煮牛肉"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/hUjBU5sebcI"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Video>
              </Videos>
            </FadeIn.Left>

            <FadeIn.Left>
              <p>
                {lang === 'ja' &&
                  '新作料理やお得な情報、キャンペーンを配信しております。'}
                {lang === 'en' && '芙蓉苑の料理人'}
                {lang === 'zh' && '正在发送新品菜肴和优惠信息，以及宣传活动。'}
              </p>
            </FadeIn.Left>
          </RecruitHeader>
          <RecruitRow>
            <RecruitCol>
              <RecruitImg to="/recruit">
                <img src={withPrefix('/img/recruit-hole.jpg')} alt="" />
                <RecruitButtons>
                  <RecruitButton>
                    {lang === 'ja' && '募集要項へ'}
                    {lang === 'en' && '芙蓉苑の料理人'}
                    {lang === 'zh' && '招募信息'}
                  </RecruitButton>
                </RecruitButtons>
              </RecruitImg>
            </RecruitCol>
            <RecruitCol>
              <RecruitBox>
                <RecruitBoxTxt>
                  <FadeIn.Left>
                    <InViewMonitor childPropsInView={{ isActive: true }}>
                      <RecruitH2>Recruit</RecruitH2>
                    </InViewMonitor>
                  </FadeIn.Left>

                  <FadeIn.Left>
                    <h3>
                      {lang === 'ja' && 'スタッフ募集'}
                      {lang === 'en' && '芙蓉苑の料理人'}
                      {lang === 'zh' && '招募店员'}
                    </h3>
                  </FadeIn.Left>

                  <FadeIn.Left>
                    <p>
                      {lang === 'ja' &&
                        '芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。'}
                      {lang === 'en' &&
                        'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                      {lang === 'zh' &&
                        '芙蓉苑重视地道的四川饮食文化，我们把真正的四川菜味道传递给日本作为我们的使命。我们向您保证，会把地地道道的，美味的“正宗的四川料理”和亲切的“笑容”送给客人。'}
                    </p>
                  </FadeIn.Left>
                </RecruitBoxTxt>
              </RecruitBox>
            </RecruitCol>
          </RecruitRow>
        </Layout.Container>
      </Layout.Section>
      <Popup show={showPopup} toggleHandler={togglePopup} padding={false}>
        <PopupImg
          src={imgCoronaVirus}
          alt="新型コロナウィルス感染症の拡大防止に向けた定休日のお知らせ"
          style={{ maxHeight: '90vh', width: 'auto' }}
        />
      </Popup>
      <Popup
        show={showPopupAbout}
        toggleHandler={togglePopupAbout}
        padding={false}
      >
        <PopupContainer>
          <h2>新型コロナウイルス感染症に対する当社の取り組みに関しまして</h2>
          <br />
          平素は格別のご高配を賜り、厚く御礼申し上げます。
          新型コロナウイルスによる感染拡大の状況を踏まえ、お客様ならびに従業員の健康と安全のため、下記の通り対策を強化いたします。
          お客様には何卒ご理解ならびにご協力を賜りますようお願い申し上げます。
          <br />
          <br />
          <h3>
            「四川料理　芙蓉苑」「芙蓉苑　火鍋城」「芙蓉麻婆麺」の主な取り組み概況
          </h3>
          <br />
          <h4>1.消毒液の設置 </h4>
          店舗入口へ消毒液を設置し、お客さまにご利用頂きやすい環境整備に努めて参ります。{' '}
          <br />
          <br />
          <h4>2.定期消毒の推進 </h4>
          テーブル・イス・ドアノブ・扉・エスカレーター・エレベーター内のボタン等、お客様の触れる機会が多い箇所のアルコール消毒頻度を従来以上に増加いたします。{' '}
          <br />
          <br />
          <h4>3.空気清浄機の設置</h4>
          空気清浄機を店内に設置し、館内の空気を清潔に保つよう心がけています。{' '}
          <br />
          <br />
          <h4>4.接客時のマスク着用</h4>
          お客さまの健康と安全ならびに公衆衛生を考慮し、一部接客スタッフにおきましてマスクを着用させて頂いております。{' '}
          <br />
          <br />
          <h4>5.お客さまへのお声掛け強化</h4>
          ご体調が優れないとスタッフが判断した場合、従来以上に積極的にお客さまにお声掛けさせて頂きます。
          <br />
          <br />
          <h4>
            6.調理器具の交換頻度向上
            <br />
          </h4>
          皿・箸・トング・スプーン等、多くのお客さまに触れる
          <br />
          頻度の高い調理器具については、交換頻度を向上させ、衛生管理を今まで以上に徹底して参ります。
          <br />
          <br />
          <h4>7.出退勤時のマスク着用</h4>
          全従業員の出退勤時のマスク着用を義務化いたします。
          <br />
          <br />
          <h4>8.出勤時の体調チェックの励行</h4>
          体温が37.5度以上の場合、また、体調が優れないと本人が判断する場合、当該スタッフは原則出社禁止といたします。
          <br />
          <br />
          <h4>9.体調不良者監理制度の導入</h4>
          体調不良者が出た場合、直接当該従業員の体調ならびに状況を監理する体制を導入いたします。
          <br />
          <br />
          <h4>10.不要不急の外出・会合出席の禁止</h4>
          スタッフについては、不要不急の（国内・海外含めた）出張ならびに複数名での会食、会合への出席を原則禁止といたします。
          <br />
          また、公私を問わず、海外渡航を当面の間禁止いたします。 お客様各位
          <br />
          ・お客様に当施設をご安心してご利用頂けるよう、ご入店時のアルコール消毒へのご協力をお願いいたします。
          <br />
          ・発熱されているお客様、また、体調の優れないお客様は、大変申し訳ございませんが、ご入店をご遠慮頂きますよう
          <br />
          お願い申し上げます。
          <br />
          ・感染ならびに拡散防止のため、従業員へのマスク着用を一部認めております。何卒ご了承頂きますようお願い申し上げます。
          <br />
          ・体調が優れない場合は、お近くのスタッフまでその旨お申し出ください。
          <br />
          ＊なお、上記については今後の日本政府による方針変更等に伴い、変更となる可能性がございます。
          <br />
          <br />
          2020年3月 株式会社トライハードダイニング
        </PopupContainer>
      </Popup>
    </>
  )
}

export const pageQuery = graphql`
  {
    allWordpressPost {
      edges {
        node {
          id
          title
          date(formatString: "YYYY/MM/DD")
          slug
        }
      }
    }
  }
`

const mapStateToProps = state => ({
  lang: state.lang,
})

export default connect(mapStateToProps)(Index)

const pink = '#e3007f'
const beige = '#fadcbe'
const lightBeige = '#FEF8F2'
const brown = '#603813'

const CoronaVirusAbout = styled.div`
  color: #fff;
  padding: 1rem;
  border: 2px solid #fff;
  width: calc(100% - 4rem);
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
  @media (min-width: 1025px) {
    max-width: 500px;
    bottom: 50px;
    justify-content: left;
    p {
      font-size: 24px;
    }
  }
  &:hover {
    background: #fff;
    color: #000;
  }
`

const PopupImg = styled.img`
  height: auto;
  max-width: 90vw;
  @media (min-width: 480px) {
    width: auto;
    max-height: 90vh;
  }
`

const PopupContainer = styled.div`
  font-size: 14px;
  line-height: 2;
  padding: 0.5rem;
  height: auto;
  overflow: scroll;
  width: 90vw;
  max-height: 90vh;
  @media (min-width: 480px) {
    width: auto;
    max-height: 90vh;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
  }
`

const Hero = styled.div`
  position: relative;
  height: 100vw;
  max-height: calc(100vh - 81px);
  overflow: hidden;
  background: #000;
  img {
    opacity: 0.5;
    width: auto;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 480px) {
      width: auto;
      height: 100vh;
    }
    @media (min-width: 1025px) {
      width: auto;
      height: 80vw;
    }
    /* animation: 15s ${Layout.ZoomImg} infinite; */
  }
`

const HeroHeader = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  top: 25%;
  left: 50%;
  width: 100%;
  max-width: calc(100% - 60px);
  h1 {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: 0;
  }

  @media (min-width: 1025px) {
    max-width: 450px;
    left: 30%;
    top: 50%;
  }
  h1 {
    line-height: 2;
    letter-spacing: 10px;
  }

  p {
    line-height: 1.4;
    letter-spacing: 5px;
  }
`
const HeroSNS = styled.div`
  color: #fff;
  width: 100%;
  max-width: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  p {
    font-family: 'Crimson Text', serif;
    font-style: italic;
    font-size: 18px;
  }
  @media (min-width: 1025px) {
    max-width: 450px;
    bottom: 30%;
    left: 30%;
    justify-content: left;
    p {
      font-size: 24px;
    }
  }
`

const Icons = styled.div`
  margin-left: 10px;
  a {
    display: inline-block;
    color: #fff;
  }
  svg {
    width: 23px !important;
    height: 23px !important;
    margin-left: 10px;
    margin-right: 10px;
  }
`

// const News = styled.section`
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
// `

// const NewsTitle = styled.div`
//   color: #fff;
//   background: ${brown};
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 15px;
//   h2 {
//     font-size: 23px;
//     font-style: italic;
//     line-height: 1.4;
//     margin-right: 15px;
//   }
//   p {
//     font-size: 15px;
//   }
//   @media (min-width: 1025px) {
//     width: 270px;
//     flex-direction: column;
//     h2 {
//       font-size: 36pxpx;
//       margin-right: 0;
//     }
//     p {
//       font-size: 14px;
//     }
//   }
// `

// const NewsBody = styled.div`
//   background: #fff;
//   display: flex;
//   width: 100%;
//   @media (min-width: 1025px) {
//     border-top: 4px solid ${brown};
//     border-bottom: 4px solid ${brown};
//     width: calc(100% - 270px);
//   }
// `

// const NewsDate = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 120px;
//   font-size: 15px;
//   padding: 15px;
// `

// const NewsContent = styled.div`
//   display: flex;
//   justify-content: left;
//   align-items: center;
//   width: calc(100% - 120px);
//   font-size: 15px;
//   padding: 15px;
// `

const Grid = styled.section`
  @media (min-width: 1025px) {
    display: grid;
    grid-template-columns: 0.5fr 4fr 3fr 0.5fr;
    grid-template-rows: 2fr 2fr 0.25fr;
  }
`

const GridBodyAbove = styled.div`
  background: ${lightBeige};
  @media (min-width: 1025px) {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 3fr;
    z-index: 2;
  }
`

const GridBodyAboveTxt = styled.div`
  padding: 30px;
  color: ${brown};
  @media (min-width: 1025px) {
    padding: 0;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 2fr 3fr 1fr;
  }
`

const GridBodyAboveTxtBox = styled.div`
  h3 {
    color: ${brown};
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: ${brown};
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    transform: translateX(150px);
    h3 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const GridBodyAboveImg = styled.div`
  position: relative;
  @media (min-width: 1025px) {
    grid-row: 2 / 3;
    img {
      object-fit: cover;
      height: 100%;
    }
  }
`

const GridBodyAboveImgWrapper = styled.div`
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 33.33333%,
    ${pink} 33.33333%,
    ${pink} 66.66666%,
    ${lightBeige} 66.66666%
  );
  background-position: 100%;
  background-size: 300%;
  ${({ isActive }) =>
    isActive &&
    css`
      animation: ${Layout.SlideBg} 1s ease-out 1 both;
    `}
`

const GridBodyBelow = styled.div`
  padding: 30px;
  @media (min-width: 1025px) {
    padding: 0;
    grid-column: 2 / 4;
    grid-row: 2 / 4;
  }
`

const GridBodyBelowBox = styled.div`
  border: 4px solid ${brown};
  @media (min-width: 1025px) {
    height: 100%;
    display: grid;
    grid-template-columns: 7.5fr 4fr 0.5fr;
    grid-template-rows: 1fr 4fr 1fr;
  }
`

const GridBodyBelowTxt = styled.div`
  padding: 30px;
  color: ${brown};
  h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 30px;
    line-height: 2;
    @media (min-width: 1025px) {
      margin-bottom: 30px;
    }
  }
  @media (min-width: 1025px) {
    padding: 15px;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    h2 {
      font-size: 52px;
      &::after {
        height: 36px;
        margin-top: -36px;
        margin-bottom: 15px;
      }
    }
    h3 {
      font-size: 18px;
      margin-bottom: 30px;
    }
    p {
      font-size: 18px;
    }
  }
`

const GridBodyBelowH2 = styled.h2`
  font-family: 'Crimson Text', serif;
  font-style: italic;
  font-size: 36px;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 23px;
    background: ${beige};
    margin-top: -23px;
    margin-bottom: 15px;
    z-index: -1;
    transition: width 1.5s ease;
  }
  @media (min-width: 1025px) {
    font-size: 52px;
    &::after {
      height: 36px;
      margin-top: -36px;
      margin-bottom: 15px;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        width: 100%;
      }
    `}
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Button = styled(Link)`
  position: relative;
  z-index: 1;
  p {
    background: linear-gradient(90deg, ${pink} 0%, ${beige} 33.3333333333%);
    background-size: 300%;
    background-position: 0;
    position: relative;
    display: block;
    padding: 20px 30px;
    margin-top: 0;
    margin-bottom: 0;
    color: #fff;
    transition: transform 0.5s ease, box-shadow 0.5s ease;

    &:before {
      font-size: 18px;
      content: '→';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &:hover {
      animation: 1s ${Layout.SlideBg} 1;
      transform: translate(10px, 10px);
    }
  }
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: ${brown};
    top: 10px;
    left: 10px;
    z-index: -1;
  }
`

const Header = styled.div`
  color: ${brown};
  margin-bottom: 30px;
  h2 {
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: ${brown};
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    margin-bottom: 50px;
    h2 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const CardRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const CardCol6 = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  @media (min-width: 415px) {
    width: 50%;
  }
`

const CardCol = styled.div`
  width: 100%;
  @media (min-width: 1025px) {
    width: 50%;
  }
`

const CardHeader = styled.h3`
  padding-top: 15px;
  padding-bottom: 15px;
  color: ${brown};
  @media (min-width: 1025px) {
    padding: 20px 20px 10px;
  }
`

const CardBody = styled.p`
  color: ${brown};
  @media (min-width: 1025px) {
    padding: 20px;
  }
  a {
    color: ${pink};
  }
`

const CardInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Sns = styled.div`
  width: 100%;
  background: linear-gradient(
      -30deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url(/img/sns.jpg);
`

const SnsSection = styled.section``

const SnsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const SnsHeader = styled.header`
  color: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  h2 {
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: #fff;
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    margin-top: 50px;
    margin-bottom: 50px;
    h2 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const SnsIcons = styled.div`
  display: flex;
`

const SnsIcon = styled.div`
  width: 33.3333333333%;
`

const SnsFacebook = styled.a`
  padding: 30px;
  background: rgba(50, 80, 148, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 30px;
  transition: background 1s ease;
  @media (min-width: 1025px) {
    font-size: 45px;
  }
  &:hover {
    background: rgba(50, 80, 148, 1);
  }
`

const SnsInstagram = styled.a`
  padding: 30px;
  background: rgba(158, 0, 93, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 30px;
  transition: background 1s ease;
  @media (min-width: 1025px) {
    font-size: 45px;
  }
  &:hover {
    background: rgba(158, 0, 93, 1);
  }
`

const SnsLine = styled.a`
  padding: 30px;
  background: rgba(0, 184, 51, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 30px;
  transition: background 1s ease;
  @media (min-width: 1025px) {
    font-size: 45px;
  }
  &:hover {
    background: rgba(0, 184, 51, 1);
  }
`

const RecruitHeader = styled.header`
  color: ${brown};
  margin-bottom: 30px;
  h2 {
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: ${brown};
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    margin-top: 50px;
    margin-bottom: 50px;
    h2 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const RecruitRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
`

const RecruitCol = styled.div`
  width: 100%;
  @media (min-width: 1025px) {
    width: 50%;
  }
`
const RecruitBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: ${lightBeige};
`

const RecruitBoxTxt = styled.div`
  padding: 30px;
  color: ${brown};
  h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 30px;
    line-height: 2;
    @media (min-width: 1025px) {
      margin-bottom: 30px;
    }
  }
  @media (min-width: 1025px) {
    padding: 15px;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    h3 {
      font-size: 18px;
      margin-bottom: 30px;
    }
    p {
      font-size: 18px;
    }
  }
`

const RecruitH2 = styled.h2`
  font-family: 'Crimson Text', serif;
  font-style: italic;
  font-size: 36px;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 23px;
    background: ${beige};
    margin-top: -23px;
    margin-bottom: 15px;
    z-index: -1;
    transition: width 1.5s ease;
  }
  @media (min-width: 1025px) {
    font-size: 52px;
    &::after {
      height: 36px;
      margin-top: -36px;
      margin-bottom: 15px;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        width: 100%;
      }
    `}
`

const RecruitImg = styled(Link)`
  position: relative;
  background: #000;
  img {
    opacity: 1;
    transition: opacity 0.5s ease;
    &:hover {
      opacity: 0.5;
    }
  }
`

const RecruitButtons = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const RecruitButton = styled.div`
  position: relative;
  width: 100%;
  max-width: 200px;
  border: 1px solid #fff;
  display: block;
  padding: 20px 30px;
  color: #fff;
  white-space: nowrap;

  &:before {
    font-size: 18px;
    content: '→';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`

const Videos = styled.div`
  margin: 2rem 0;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const Video = styled.div`
  position: relative;
  margin: 2rem 0;

  @media (min-width: 768px) {
    margin: 0;
    width: calc((100% - 2rem) / 3);
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
